<template lang="pug">
  div
    el-alert(
      title="Bu bölümü görüntüleme yetkiniz yok"
      v-if="!can('warehouses.list')"
      :closable="false"
      type="error"
      show-icon
      center
    )
    el-card(body-style="padding:0" v-if="can('warehouses.list')")
      div(slot="header")
        el-form(inline)
          el-form-item
            el-button(
              type="primary"
              @click="create"
              icon="el-icon-plus"
              :disabled="!can('warehouses.create')"
              size="mini"
            ) Oluştur
          el-form-item
            el-input.pull-right(
              v-model="search"
              suffix-icon="el-icon-search"
              placeholder="Ara"
              clearable
              size="mini"
            )
      el-table(:data="filtered" @row-dblclick="edit" stripe)
        el-table-column(prop="name" label="Depo")
        el-table-column(prop="parent.name" label="Şube")
        el-table-column(prop="address" label="Adres")
        el-table-column(prop="id" width="120px")
          template(v-slot="props")
            el-button-group
              el-button.micro(icon="el-icon-edit" @click="edit(props.row)" size="mini")
              el-button.micro(icon="el-icon-delete" @click="delete(props.row)" size="mini" type="danger" disabled)
    el-dialog(
      :visible.sync="modal"
      :title="warehouse.hasOwnProperty('id') ? 'Düzenle' : 'Oluştur'"
      center
      width="400px"
    )
      el-form(v-model="warehouse" label-position="top")
        el-form-item(label="Depo adı")
          el-input(v-model="warehouse.name")
        el-form-item(label="Şube")
          el-select(v-model="warehouse.parent_id" filterable style="width: 100%")
            el-option(
              v-for="b in branches"
              :key="b.id"
              :value="b.id"
              :label="b.name"
            )
        el-form-item(label="Adres")
          el-input(v-model="warehouse.address")
      el-button(@click="save" type="primary") Kaydet
</template>
<script>
import Company from '@/models/Company'
export default {
  name: 'Warehouses',
  data() {
    return {
      fetching: false,
      saving: false,
      modal: false,
      warehouse: new Company({
        name: '',
        type: 'branch',
        parent_id: null,
        address: null
      }),
      branches: [],
      warehouses: [],
      search: ''
    }
  },
  async created() {
    if (this.can('warehouses.list')) {
      this.branches = await Company
        .where('type', 'branch')
        .get()
      await this.fetch()
    }
  },
  methods: {
    async fetch() {
      try{
        this.fetching = true
        this.warehouses = await Company
          .include(['parent'])
          .where('type', 'warehouse')
          .get()
        this.fetching = false
      } catch({ response }) {
        this.$message.error(response.data.message)
        this.fetching = false
      }
    },
    create() {
      this.warehouse = new Company({
        type: 'warehouse',
        name: '',
        parent_id: null,
        address: null
      })
      this.modal = true
    },
    edit(row) {
      this.warehouse = row
      this.modal = true
    },
    async save() {
      try {
        this.saving = true
        await this.warehouse.save()
        this.saving = false
        this.modal = false
        await this.fetch()
      } catch({ response }) {
        this.$message.error(response.data.message)
        this.saving = false
      }
    },
    delete() {

    }
  },
  computed: {
    filtered: function(){
      return this.warehouses.filter(a => a.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  }
}
</script>
